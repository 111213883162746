import * as React from 'react';
// import heroImage from 'assets/images/plp-hero.png';
import { HTMLAttributes } from 'react';
import ButtonAsNativeLink, { Props as ButtonProps } from 'styleguide/components/Button/ButtonAsNativeLink';

const ActionButton = ({
  children,
  target = null,
  color = null,
  ...props
}: ButtonProps & React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
  <ButtonAsNativeLink
    buttonType="anchor"
    target={target}
    color={color}
    className="!inline-flex !cursor-pointer !justify-center !items-center
     !rounded-lg
    no-underline text-white transition whitespace-nowrap md:!whitespace-normal
    md:h-32
    hover:shadow p-1.5 hover:md:shadow-xl active:bg-blue-400/40
    md:!text-2xl md:!font-hvBold md:text-center lg:!text-2xl
    shadow-2xl
    "
    {...props}
  >
    {children}
  </ButtonAsNativeLink>
);

const Hero = ({
  onClick,
  ...props
}: React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
  <div
    className="lg:max-w-screen-4xl  bg-blue w-full h-96 md:h-128 overflow-clip"
    // style={{
    //   backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.75) 15%, rgba(0, 0, 0, 0.15) 85.94%), url(${heroImage})`,
    //   backgroundSize: 'cover',
    //   backgroundPosition: 'center',
    // }}
    {...props}
  >
    <div
      className="text-white flex flex-col lg:flex-row h-full max-w-base space-y-2 md:space-y-4 p-4
    sm:p-8 lg:space-x-4 xl:space-x-16 mx-auto"
    >
      <div className="flex flex-col lg:justify-center lg:items-start sm:basis-1/2 space-y-2 md:space-y-4">
        <h1 className="text-center lg:text-left font-hvBold text-2xl sm:text-3xl md:text-5xl xl:text-6xl">
          What are you looking for?
        </h1>
        <div className="font-hvLite text-sm/5 sm:text-lg/6 md:text-base">
          From pre-made designs to custom projects and lightning-fast turnarounds, navigate to the service
          that perfectly suits your vision.
          <span className="-xs:hidden">&nbsp;Let&apos;s make your ideas come to life, effortlessly.</span>
        </div>
      </div>
      <div className="flex h-full lg:w-full lg:basis-1/2 justify-center items-center text-red">
        <div className="grid grid-cols-1 w-full md:grid-cols-2 gap-4 md:gap-8 sm:place-content-center">
          <ActionButton color="orange" target={null} buttonType="anchor" onClick={event => onClick(event)}>
            I already have a design
          </ActionButton>
          <ActionButton color="orange" target="/design-services" buttonType="link">
            I need a design made
          </ActionButton>
          <ActionButton color="orange" target="/contact-us" buttonType="link">
            I need a custom quote
          </ActionButton>
          <ActionButton color="orange" target="rush-critical" buttonType="link">
            I need my project immediately
          </ActionButton>
        </div>
      </div>
    </div>
  </div>
);

export default Hero;
