import * as React from 'react';
import MainSection from './MainSection/MainSection';
import Footer from 'styleguide/components/Footer/Footer';
import Meta from 'styleguide/components/Meta/Meta';
import Reviews from 'styleguide/components/Reviews/Reviews';
import CallToActionRow from 'bundles/App/pages/CategoryPage/row/CallToActionRow';
import { insightsCards } from 'bundles/App/pages/service/FranchiseSolutions/Brands/types';
import InfoCards from 'styleguide/components/InfoCards/InfoCards';
import Breadcrumb from 'styleguide/components/Breadcrumb/Breadcrumb';
import colorCardsImg from 'assets/images/colors-cards.png';
import Wrapper from 'styleguide/components/Wrapper/Wrapper';
import Articles from 'bundles/App/pages/ProductDetailsPage/AdditionalInfo/Articles/Articles';
import AppContext from 'contexts/AppContext/AppContext';
import { CmsCategoryPage, InfoBlock } from 'contexts/AppContext/types';
import Hero from 'bundles/App/pages/ProductListingPage/Hero/Hero';

const ProductListingPage = () => {
  const appContext = React.useContext(AppContext);
  const cms: CmsCategoryPage = appContext.store.cmsPages.find(
    elem => elem.fullPath === '/products',
  ) as CmsCategoryPage;
  const articles = (cms?.rows?.find(row => row.type === 'Comfy::Cms::Page::Row::InfoBlock') ||
    null) as InfoBlock | null;

  return (
    <>
      <Meta
        title="Custom Print Products - Instant Quotes Online | Printivity"
        description={
          'Get an instant quote on your printing products with Printivity! Browse our selection of ' +
          'custom print products.  '
        }
        keywords={
          'print products, print quotes, print products listing, browse print products, custom print ' +
          'products, print marketing products, print promotional products'
        }
        canonical="https://www.printivity.com/products"
      />
      <main className="mx-auto max-w-screen-4xl">
        <Breadcrumb
          className="pb-4 pl-4 text-left xl:pl-12 3xl:pl-40"
          pages={[{ label: 'Home', link: '/' }, { label: 'All Products' }]}
        />
        <Hero
          onClick={() => {
            const elem = document.getElementById('product-list');
            if (elem) {
              elem.scrollIntoView({ behavior: 'smooth' });
            }
          }}
        />
        <Wrapper id="product-list" className="-mt-20 pt-20">
          <div className="heading-bold-sm mt-16 -md:text-xl">Start Your Project Here</div>
          <MainSection />
          <InfoCards infoCards={insightsCards} iconSize="sm" alignment="left" />
        </Wrapper>
        <CallToActionRow
          callToAction={{
            type: 'Comfy::Cms::Page::Row::CallToAction',
            title: 'Custom designs start as low as $49',
            subtextLine1: 'Get an expert consultation on your project from Printivity’s creative team.',
            subtextLine2: 'Starting at $49, we’ll work with you to bring your project to life.',
            backgroundImageUrl: colorCardsImg,
          }}
        />
        <Wrapper>
          {articles && <Articles infoBlock={articles} />}
          <Reviews />
        </Wrapper>
        <Footer />
      </main>
    </>
  );
};

export default ProductListingPage;
